import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VBtn,{staticClass:"mb-n1",attrs:{"text":"","color":"primary-color","title":"Back to Letter of Authorization"},on:{"click":_vm.back}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("arrow-left")]),_vm._v(" Back to Letter of Authorization ")],1),_c(VCard,{staticClass:"pa-0 my-4"},[_c(VRow,{staticClass:"mt-0"},[_c(VCol,{staticClass:"px-3 pt-0"},[_c('company-editor',{ref:"editor",attrs:{"company-id":_vm.companyId},on:{"saved":_vm.safeBack,"cancelled":_vm.safeBack},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c(VCardTitle,{staticClass:"mt-2"},[_c(VSkeletonLoader,{attrs:{"loading":"","type":"heading","width":"40%"}})],1),_c(VCardTitle,[_c(VSkeletonLoader,{attrs:{"loading":"","type":"heading","width":"70%"}})],1),_c(VCardTitle,[_c(VSkeletonLoader,{attrs:{"loading":"","type":"heading","width":"70%"}})],1),_c(VCardText,[_c(VSkeletonLoader,{attrs:{"loading":"","type":"paragraph"}})],1),_c(VCardTitle,[_c(VSkeletonLoader,{attrs:{"loading":"","type":"heading","width":"70%"}})],1)]},proxy:true}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }