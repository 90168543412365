<template>
    <v-container>
        <v-btn
            text
            color="primary-color"
            class="mb-n1"
            title="Back to Letter of Authorization"
            @click="back"
        >
            <v-icon small left>arrow-left</v-icon>
            Back to Letter of Authorization
        </v-btn>
        <v-card class="pa-0 my-4">
            <v-row class="mt-0">
                <v-col class="px-3 pt-0">
                    <company-editor
                        ref="editor"
                        :company-id="companyId"
                        @saved="safeBack"
                        @cancelled="safeBack"
                    >
                        <template #loading>
                            <v-card-title class="mt-2">
                                <v-skeleton-loader
                                    loading
                                    type="heading"
                                    width="40%"
                                />
                            </v-card-title>
                            <v-card-title>
                                <v-skeleton-loader
                                    loading
                                    type="heading"
                                    width="70%"
                                />
                            </v-card-title>
                            <v-card-title>
                                <v-skeleton-loader
                                    loading
                                    type="heading"
                                    width="70%"
                                />
                            </v-card-title>
                            <v-card-text>
                                <v-skeleton-loader loading type="paragraph" />
                            </v-card-text>
                            <v-card-title>
                                <v-skeleton-loader
                                    loading
                                    type="heading"
                                    width="70%"
                                />
                            </v-card-title>
                        </template>
                    </company-editor>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { CompanyEditor } from '@/components/CompanyEditor';

import type { NavigationGuardNext, Route } from 'vue-router';

Component.registerHooks(['beforeRouteLeave']);

@Component({
    components: {
        CompanyEditor
    }
})
export default class LoaCompanyEditor extends Vue {
    $refs!: {
        editor: CompanyEditor;
    };

    isLeaving = false;

    isFormEvent = false;

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get companyId() {
        return +this.$route.params.companyId;
    }

    back() {
        // onLeaveHook will trigger @saved event, causing double redirect
        // which we don't want
        if (!this.isLeaving) {
            this.$router.push(
                `/announcements/publish/${this.announcementId}/loa`
            );
        }
    }

    safeBack() {
        this.isFormEvent = true;

        this.back();
    }

    onLeaveHook() {
        if (!this.isFormEvent && this.$refs.editor.$refs.form.isDirty) {
            return this.$refs.editor.save();
        }

        return Promise.resolve();
    }

    beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
        this.isLeaving = true;

        return this.onLeaveHook().then(() => next());
    }
}
</script>
